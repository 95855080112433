/* fonts */
@import url('https://fonts.googleapis.com/css2?family=Acme&family=Roboto&family=Source+Sans+Pro:ital@1&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ubuntu&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');

/*
===============
Variables
===============
*/

:root {
  /* bcg */
  --toggle-color: #f4f6f0;
  --nav-bcg: linear-gradient(rgba(160, 160, 160, 0.1), rgba(160, 160, 160, 0.1)),
    url(./images/hero/hero-bcg-light.svg) center repeat fixed;
  --hero-bcg: linear-gradient(
      rgba(160, 160, 160, 0.1),
      rgba(160, 160, 160, 0.1),
      rgba(250, 250, 250, 0.5)
    ),
    url(./images/hero/hero-bcg-light.svg) center repeat;
  --contact-bcg: linear-gradient(
      rgba(160, 160, 160, 0.3),
      rgba(160, 160, 160, 0.3)
    ),
    url(./images/contact/contact-bcg.png) center repeat;

  /* white colors */
  --clr-body-bcg: #fff;
  --clr-body-bcg-2: rgb(250, 250, 250);
  --clr-body-bcg-3: #f0f0f0;
  /* black colors  */
  --clr-font: #101114;
  --clr-font-2: #191a1d;
  --clr-font-3: #252525;
  /* primary colors */
  --clr-primary: #008037;
  --clr-secondary: #00995e;
  --clr-tertiary: #a2e2be;
  /* shadows */
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  /* other colors */
  --clr-white: #fff;
  --clr-black: #222;
  --clr-primary-gradient: linear-gradient(
    to top right,
    var(--clr-secondary),
    var(--clr-primary)
  );
  /* darkest grey - used for headings */
  --clr-grey-1: hsl(209, 61%, 16%);
  --clr-grey-2: hsl(211, 39%, 23%);
  --clr-grey-3: hsl(209, 34%, 30%);
  --clr-grey-4: hsl(209, 28%, 39%);
  /* grey used for paragraphs */
  --clr-grey-5: hsl(210, 22%, 49%);
  --clr-grey-6: hsl(209, 23%, 60%);
  --clr-grey-7: hsl(211, 27%, 70%);
  --clr-grey-8: hsl(210, 31%, 80%);
  --clr-grey-9: hsl(212, 33%, 89%);
  --clr-grey-10: #eef3f7;

  --clr-tools-text: var(--clr-grey-3);
  /* font families */
  --ff-primary: 'Roboto', sans-serif;
  --ff-secondary: 'Ubuntu', sans-serif;
  --ff-tertiary: 'Poppins', sans-serif;
  /* transitions */
  --transition: all 0.3s linear;
  --long-transition: all 1s ease;
  /* spacing */
  --spacing: 0.2rem;
  /* radius */
  --radius: 0.25rem;
  /* width */
  --max-width: 1280px;
  --fixed-width: 560px;
}
.dark {
  /* bcg */
  --toggle-color: #fce570;
  --nav-bcg: linear-gradient(rgba(4, 1, 1, 0.1), rgba(4, 1, 1, 0.1)),
    url(./images/hero/hero-bcg-dark.svg) center repeat fixed;
  --hero-bcg: linear-gradient(
      rgba(4, 1, 1, 0.1),
      rgba(5, 5, 5, 0.1),
      rgba(4, 1, 1, 0.1)
    ),
    url(./images/hero/hero-bcg-dark.svg) center repeat;
  --contact-bcg: linear-gradient(
      to bottom right,
      rgba(20, 20, 20, 0.75),
      rgba(20, 20, 20, 0.5),
      rgba(20, 20, 20, 0.75)
    ),
    url(./images/contact/contact-bcg.png) center repeat;

  /* black colors */
  --clr-body-bcg: #0a0a0b;
  --clr-body-bcg-2: #0e0d0d;
  --clr-body-bcg-3: #181717;
  /* white colors  */
  --clr-font: rgba(255, 255, 255, 0.9);
  --clr-font-2: #f5f6f7;
  --clr-font-3: #f0f0f0;

  --clr-tools-text: var(--clr-grey-7);
  /* shadows */
  --light-shadow: 0 2px 5px rgba(255, 255, 255, 0.2);
  --dark-shadow: 0 2px 5px rgba(255, 255, 255, 0.5);
}

.light {
  /* bcg */
  --toggle-color: #f4f6f0;
  --nav-bcg: linear-gradient(rgba(160, 160, 160, 0.1), rgba(160, 160, 160, 0.1)),
    url(./images/hero/hero-bcg-light.svg) center repeat fixed;
  --hero-bcg: linear-gradient(
      rgba(160, 160, 160, 0.1),
      rgba(160, 160, 160, 0.1),
      rgba(250, 250, 250, 0.5)
    ),
    url(./images/hero/hero-bcg-light.svg) center repeat;
  --contact-bcg: linear-gradient(
      rgba(160, 160, 160, 0.3),
      rgba(160, 160, 160, 0.3)
    ),
    url(./images/contact/contact-bcg.png) center repeat;

  /* white colors */
  --clr-body-bcg: #fff;
  --clr-body-bcg-2: rgb(250, 250, 250);
  --clr-body-bcg-3: #f0f0f0;
  /* black colors  */
  --clr-font: #101114;
  --clr-font-2: #191a1d;
  --clr-font-3: #252525;

  --clr-tools-text: var(--clr-grey-3);
  /* shadows */
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

/*
===============
Global Styles
===============
*/
html {
  scroll-behavior: smooth;
}

html::-webkit-scrollbar {
  width: 1em;
}

html::-webkit-scrollbar-thumb {
  background: var(--clr-secondary);
  transition: var(--transition);
}
html::-webkit-scrollbar-thumb:hover {
  background: var(--clr-secondary);
  transition: var(--transition);
}
html::-webkit-scrollbar-track {
  background: #ccc;
}

::selection {
  color: var(--clr-white);
  background: var(--clr-secondary);
}

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: var(--ff-tertiary);
  background: var(--clr-body-bcg);
  color: var(--clr-font);
  line-height: 1.5;
  font-size: 0.875rem;
  cursor: url('./images/cursor.svg'), auto;
}
ul {
  list-style-type: none;
}
a {
  text-decoration: none;
  cursor: pointer;
}
img:not(.nav-logo) {
  width: 100%;
}
img {
  display: block;
}

h1,
h2,
h3,
h4 {
  letter-spacing: var(--spacing);
  text-transform: capitalize;
  line-height: 1.25;
  margin-bottom: 0.75rem;
}
h1 {
  font-size: 2.8rem;
}
h2 {
  font-size: 1.9rem;
}
h3 {
  font-size: 1.2rem;
}
h4,
.skills-title,
.project-title {
  font-size: 1rem;
}
p {
  margin-bottom: 1.25rem;
  color: var(--clr-font);
}
@media screen and (min-width: 768px) {
  h1 {
    font-size: 3.75rem;
  }
  h2 {
    font-size: 2.5rem;
  }
  h3 {
    font-size: 1.75rem;
  }
  h4,
  .skills-title,
  .project-title {
    font-size: 1.1rem;
  }
  body {
    font-size: 1rem;
  }
  h1,
  h2,
  h3,
  h4 {
    line-height: 1;
  }
}

/* global classes */

.btn {
  text-transform: uppercase;
  background: var(--clr-primary);
  color: var(--clr-white);
  padding: 0.5rem 1.25rem;
  letter-spacing: var(--spacing);
  display: inline-block;
  font-weight: 700;
  transition: var(--transition);
  font-size: 1rem;
  cursor: pointer;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 2rem;
}
.btn:hover {
  background: var(--clr-secondary);
}
@media screen and (max-width: 548px) {
  .btn {
    font-size: 0.75rem;
  }
}

/* section */

.section {
  padding: 5rem 0;
}

.section-center {
  padding: 1rem 0;
  width: 90vw;
  margin: 0 auto;
  max-width: var(--max-width);
}
@media screen and (min-width: 992px) {
  .section-center,
  .contact-center {
    width: 95vw;
  }
}

.underline {
  width: 7rem;
  height: 0.25rem;
  margin-top: 0.4rem;
  background: var(--clr-primary);
  margin-left: auto;
  margin-right: auto;
  transition: var(--long-transition);
}
.section-title:hover .underline {
  background: var(--clr-primary-gradient);
  width: 13rem;
}

.section-title {
  font-family: var(--ff-primary);
  margin-bottom: 4rem;
  text-align: center;
  text-transform: capitalize;
  padding: 0 1rem;
}
.section-title span {
  font-weight: bolder;
  text-shadow: 1px 1px 1px var(--clr-tertiary);
}

.loader {
  margin: 10rem auto;
  width: 5rem;
  height: 5rem;
  border: 4px solid var(--clr-grey-9);
  border-radius: 50%;
  border-top-color: var(--clr-secondary);
  animation: loader 1s linear infinite;
}
@keyframes loader {
  to {
    transform: rotate(360deg);
  }
}

/* 
======================
WIDGET
======================
*/

.widget {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  background: var(--clr-white);
  border-radius: 50%;
  padding: 0.5rem;
  transition: transform 0.5s ease-in-out;
  outline: 1px solid var(--clr-black);
  outline-offset: -2px;
  z-index: 10;
}
.widget:hover {
  transform: rotate(1080deg);
}

.widget-img {
  width: 2.75rem;
  height: 2.75rem;
}

@media (max-width: 481px) {
  .widget {
    bottom: 0.75rem;
    right: 0.75rem;
  }
  .widget-img {
    width: 2.25rem;
    height: 2.25rem;
  }
}

/* 
======================
NAV ALERT
======================
*/

.nav-alert {
  background: var(--clr-primary-gradient);
  text-align: center;
  color: var(--clr-white);
  padding: 0.75rem 0.5rem;
}

.nav-alert a {
  text-decoration: underline;
  color: white;
}

/* 
======================
NAVBAR
======================
*/
.toggle-theme {
  display: none;
  align-self: center;
  line-height: 0;
  cursor: pointer;
}
.toggle-theme .fa {
  font-size: 1.25rem;
  color: var(--toggle-color);
  background: transparent;
}

nav {
  background: var(--clr-body-bcg);
  padding: 0.75rem 1rem;
  z-index: 100;
}
.nav-fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: var(--clr-body-bcg);
  box-shadow: var(--light-shadow);
}
.nav-center {
  margin: 0 auto;
  max-width: var(--max-width);
}
.nav-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.nav-btn {
  align-self: center;
  font-size: 2rem;
  color: var(--clr-primary);
  background: transparent;
  border-color: transparent;
  transition: var(--transition);
  cursor: pointer;
  outline: none;
  transform: rotate(90deg);
  animation: bounce 1.5s linear infinite;
  line-height: 0;
}
.nav-btn:hover {
  color: var(--clr-secondary);
}
@keyframes bounce {
  0% {
    transform: scale(1);
    color: var(--clr-primary);
  }
  50% {
    transform: scale(1.1);
    color: var(--clr-secondary);
  }
  100% {
    transform: scale(1);
    color: var(--clr-primary);
  }
}

.logo {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.logo img {
  height: 3rem;
  width: 3rem;
}

.logo h5 {
  color: var(--clr-primary);
  font-size: 1.75rem;
  letter-spacing: -1px;
  transition: var(--transition);
}
.logo h5:hover {
  color: var(--clr-secondary);
}
.nav-links {
  display: none;
  width: 100%;
  max-width: 450px;
  padding: 0 1rem;
}
.nav-links .link {
  text-transform: uppercase;
  color: var(--clr-secondary);
  font-weight: bold;
  text-shadow: 1px 1px 1px var(--clr-body-bcg);
  transition: var(--transition);
  letter-spacing: 2px;
  padding: 0.1rem 0 0.5rem;
  position: relative;
}
.nav-links .link::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 0;
  background: var(--clr-secondary);
  transition: var(--transition);
}

.nav-links .link:hover {
  color: var(--clr-primary);
}
.nav-links .link:hover::after {
  width: 100%;
}

@media screen and (min-width: 792px) {
  nav {
    background: var(--nav-bcg);
  }
  .nav-center {
    display: grid;
    grid-template-columns: auto 1fr auto;
    gap: 1rem;
    align-items: center;
  }
  .nav-btn {
    display: none;
  }
  .nav-links {
    justify-self: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-right: 4rem;
  }
  .toggle-theme {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
}

/* 
======================
SIDEBAR
======================
*/

.sidebar .toggle-theme {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin-left: 1rem;
}

.sidebar-header-col {
  display: flex;
  align-items: center;
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--clr-body-bcg-3);
  transition: var(--transition);
  transition-duration: 0.2s;
  transform: translate(-100%);
  z-index: -1;
}
.show-sidebar {
  transform: translate(0);
  z-index: 999;
}
.sidebar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}
.close-btn {
  font-size: 2rem;
  color: rgb(226, 39, 39);
  background: transparent;
  border-color: transparent;
  transition: var(--transition);
  cursor: pointer;
  outline: none;
}
.close-btn:hover {
  color: rgb(194, 14, 14);
}

.sidebar-links {
  margin: 1rem 0;
}
.sidebar-links .link {
  text-transform: uppercase;
  padding: 0.75rem 1rem;
  font-size: 1.25rem;
  letter-spacing: 3px;
  display: block;
  color: var(--clr-secondary);
  font-weight: bold;
  text-shadow: 1px 1px 1px var(--clr-body-bcg);
  transition: var(--transition);
}
.sidebar-links .link:hover {
  color: var(--clr-primary);
  background: var(--clr-body-bcg-2);
  padding-left: 1.5rem;
  border-left: 4px solid var(--clr-primary);
}
.sidebar-icons {
  margin: 2.5rem auto;
  text-align: center;
  padding: 0 2rem;
}
.icons-container {
  padding: 1.5rem 2rem;
  border: 4px solid var(--clr-primary);
  display: grid;
  grid-template-columns: 1fr 1fr;
  place-items: center;
  margin-top: 2rem;
  background: var(--clr-body-bcg-2);
  gap: 1rem 0;
}
.icons-container span {
  display: none;
}

.sidebar-icons .social-icon {
  transition: var(--transition);
  font-size: 2rem;
}

.sidebar-icons .social-icon:hover {
  color: var(--clr-primary);
}
.sidebar-icons .social-icon:hover .fa {
  color: var(--clr-primary);
  background: transparent;
}

@media screen and (min-width: 792px) {
  .sidebar {
    display: none;
  }
}

/* 
======================
HERO
======================
*/
header {
  background: var(--hero-bcg);
}
.banner {
  min-height: calc(100vh - 72px - 48px);
  display: grid;
  place-items: center;
  padding-bottom: 7rem;
}
.banner-container {
  display: grid;
}
.hero-img {
  display: none;
  width: 25rem;
}

.hero-image {
  width: 25rem;
  height: 30rem;
  object-fit: contain;
}

.hero-info {
  text-align: center;
}
.hero-info h1 {
  text-shadow: 1px 1px 1px var(--clr-primary);
  font-family: var(--ff-primary);
}
#my-name span {
  color: var(--clr-primary);
  text-shadow: 1px 1px 1px var(--clr-font);
}
.hero-info p {
  margin: 1.5rem 0;
  margin-top: 0.5rem;
}
.hero-btn-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
  text-align: center;
  max-width: 30rem;
  margin: 0 auto;
}
.hero-btn {
  align-self: center;
  border: 2px solid var(--clr-primary);
  transition: all 0.5s ease-out;
}
.hero-btn:last-child {
  background: transparent;
  color: var(--clr-primary);
}
.hero-btn:hover {
  background: transparent;
  color: var(--clr-primary);
}
.hero-btn:last-child:hover {
  background: var(--clr-primary);
  color: var(--clr-white);
}

#my-name {
  margin: 0 auto;
  width: 400px;
  white-space: nowrap;
  opacity: 0;
  line-height: 1.5;
  animation: type 4s steps(20, end) alternate, blink 4s step-end;
  animation-fill-mode: forwards;
  border-right: 2px solid var(--clr-font);
  overflow: hidden;
}

@media screen and (min-width: 768px) {
  #my-name {
    width: 520px;
  }
  .hero-btn-container {
    column-gap: 2rem;
  }
}
@media screen and (max-width: 548px) {
  .hero-btn {
    font-size: 0.75rem;
  }
  #my-name {
    font-size: 2rem;
    width: 320px;
  }
}

/* text animation */
@keyframes type {
  0% {
    width: 0;
  }
  1% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes blink {
  15% {
    border-color: transparent;
  }
  30% {
    border-color: var(--clr-font);
  }
  45% {
    border-color: transparent;
  }
  60% {
    border-color: var(--clr-font);
  }
  75% {
    border-color: transparent;
  }
  90% {
    border-color: var(--clr-font);
  }
  100% {
    border-color: transparent;
  }
}

@media screen and (min-width: 992px) {
  .banner-container {
    grid-template-columns: 1fr auto;
    gap: 3rem;
    align-items: center;
  }
  .hero-img {
    display: block;
  }
  .hero-info {
    text-align: left;
  }
  .hero-info p {
    max-width: 40rem;
  }
  .hero-btn-container,
  #my-name {
    margin: 0;
  }
}

/* 
======================
ABOUT
======================
*/

.about-center {
  display: grid;
  gap: 2rem;
}
.about-img,
.links-page-img {
  margin: 0 auto;
  max-width: 300px;
  border-radius: 50%;
  overflow: hidden;
  padding: 0.1rem;
  border: 3px solid var(--clr-primary);
}
.links-page-img {
  min-height: 134.8px;
}
.about-image,
.links-page-image {
  border-radius: 50%;
  width: 100%;
  object-fit: contain;
  width: 15rem;
}

.about-info {
  text-align: center;
}

.about-info a {
  color: var(--clr-primary);
  text-decoration: underline;
}

@media screen and (min-width: 992px) {
  .about-center {
    grid-template-columns: 30% 1fr;
    align-items: center;
    padding-left: 3rem;
    column-gap: 4rem;
  }
  .about-info {
    padding: 1.5rem 0 1.5rem 3rem;
    max-width: 42rem;
    justify-self: center;
  }
  .about-info p {
    text-align: left;
  }

  .about-img {
    display: block;
    position: relative;
    max-width: unset;
  }
  .about-image {
    position: relative;
    box-shadow: var(--light-shadow);
    border-radius: 0.25rem;
    object-fit: cover;
  }
}

/* 
======================
PROJECTS
======================
*/

.projects {
  background: var(--clr-body-bcg-2);
}

.project:first-child {
  margin-top: 0;
}
.project:last-child {
  margin-bottom: 0;
}

.project {
  margin: 4rem auto;
  display: grid;
  box-shadow: var(--light-shadow);
}

.project-img {
  border-top-left-radius: var(--radius);
  border-top-right-radius: var(--radius);
}

.project-details {
  background: var(--clr-body-bcg);
}

.project-info {
  padding: 0 1.2rem;
  text-align: center;
}

.project-title {
  text-align: center;
  margin-top: 1rem;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.project-text-toggle {
  border: none;
  background: transparent;
  color: var(--clr-tools-text);
  letter-spacing: 1px;
}

.project-text {
  margin: 0 auto;
  display: none;
}
.project-text.show-project-text {
  display: block;
}

.project-tools {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  margin: 0.75rem auto;
  text-transform: capitalize;
  gap: 0.75rem;
}
.project-tools span {
  background: var(--clr-body-bcg-3);
  color: var(--clr-tools-text);
  padding: 0.2rem 0.35rem;
  border-radius: 4px;
}
.project-footer {
  margin-top: 1.25rem;
  text-transform: capitalize;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 1.2rem;
  background: var(--clr-font-2);
  width: 100%;
  border-bottom-left-radius: var(--radius);
  border-bottom-right-radius: var(--radius);
}
.project-footer a:first-child {
  order: 2;
  text-decoration: underline;
}
.project-footer a:last-child {
  order: 1;
}
.project-footer a {
  color: var(--clr-body-bcg);
  transition: var(--transition);
  align-items: center;
}
.project-footer .fa {
  font-size: 1.25rem;
  margin-right: 0.25rem;
  vertical-align: text-top;
}
.project-footer a:hover {
  color: var(--clr-primary);
}

@media screen and (min-width: 792px) {
  .project {
    grid-template-columns: repeat(12, 1fr);
    align-items: center;
    box-shadow: none;
    margin: 8rem auto;
  }
  .project-img {
    grid-column: 1 / span 8;
    grid-row: 1 / 1;
    height: 32rem;
    border-radius: var(--radius);
    box-shadow: var(--light-shadow);
    position: relative;
  }
  .project-image {
    height: 32rem;
    width: 18rem;
    object-fit: cover;
  }

  .project-img::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to bottom left,
      rgba(0, 128, 55, 0.5),
      rgba(0, 0, 0, 0.5)
    );
    transition: var(--transition);
  }

  .project:nth-of-type(even) .project-img::after {
    background: linear-gradient(
      to bottom right,
      rgba(0, 128, 55, 0.5),
      rgba(0, 0, 0, 0.5)
    );
  }

  .project-img:hover::after {
    opacity: 0;
  }

  .project-details {
    border-radius: var(--radius);
    box-shadow: var(--light-shadow);
    z-index: 1;
    grid-column: 6 / -1;
    grid-row: 1 / 1;
    padding: 2rem 1.5rem;
  }

  .project:nth-of-type(even) .project-img {
    grid-column: 5 / -1;
    grid-row: 1 / 1;
  }
  .project:nth-of-type(even) .project-details {
    grid-column: 1 / span 7;
    grid-row: 1 / 1;
    text-align: left;
  }

  .project-title {
    text-align: left;
  }
  .project-info {
    text-align: left;
  }
  .project-text-toggle {
    display: none;
  }
  .project-text {
    display: block;
    margin: 1rem 0;
  }
  .project-tools {
    margin: 0;
    justify-content: left;
  }
  .project-footer {
    border-radius: 0;
    background: transparent;
    display: block;
    margin-top: 1rem;
  }
  .project-footer:first-child {
    order: 1;
  }
  .project-footer:last-child {
    order: 2;
  }
  .project-footer a {
    color: var(--clr-font-2);
    margin-right: 1.5rem;
  }
}

.btn-container {
  margin: 3rem auto 0;
  max-width: var(--max-width);
  text-align: center;
  padding: 0 1rem;
}

/* 
======================
SKILLS
======================
*/

.skills-title-container {
  position: relative;
  margin: 2rem 0 1.5rem;
}

.skills-title {
  display: inline-block;
  background: var(--clr-body-bcg);
  padding: 0 0.75rem;
  margin-bottom: 0;
}

.skills-line {
  height: 2px;
  background: var(--clr-primary-gradient);
  position: absolute;
  width: 60%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}

.skills-center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1.5rem 0.75rem;
}

.skill-icon {
  width: 2.5rem;
  object-fit: contain;
  height: 2.5rem;
  transition: var(--transition);
}
.skill-icon:hover {
  transform: scale(1.05);
}

/* 
======================
CONTACT
======================
*/

.contact {
  text-align: center;
  background: var(--contact-bcg);
}
.contact-info {
  padding: 2rem;
}
.contact-info h2 {
  text-shadow: 2px 2px 2px var(--clr-body-bcg);
  margin-bottom: 1.5rem;
}
.contact-info p {
  max-width: 35rem;
}

.social-icons {
  background: #202020;
}
.social-icons h4 {
  display: none;
}
.social-icons-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  max-width: var(--fixed-width);
  padding: 0.8rem 0;
  margin: 0 auto;
  gap: 1rem;
}
.social-icon {
  font-size: 2rem;
  transition: var(--transition);
  line-height: 1;
  justify-self: center;
}
@media screen and (max-width: 792px) {
  .social-icon {
    line-height: 0;
  }
}
.social-icon img {
  transition: var(--transition);
  vertical-align: middle;
}
.twitter {
  color: #00a7e7;
}
.instagram {
  color: #ea6960;
}
.linkedin {
  color: #238fc8;
}
.youtube {
  color: #fd3131;
}
.codepen {
  color: #4ec3b8;
}
.github {
  color: var(--clr-font);
  background: var(--clr-body-bcg);
  border-radius: 50%;
}

.social-icons-container span {
  display: none;
}
.social-icon img {
  width: 2.25rem;
  height: 2.25rem;
}

.social-icon:hover {
  transform: scale(1.1);
}

@media screen and (min-width: 792px) {
  .contact-center {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    padding: 3rem 2rem;
    margin: 0 auto;
    max-width: var(--max-width);
    text-align: left;
    gap: 2rem;
  }
  .social-icons {
    background: transparent;
    padding: 0 4rem;
  }
  .social-icons h4 {
    display: block;
    text-transform: none;
    text-align: center;
    margin-bottom: 1rem;
  }
  .social-icons-container {
    display: grid;
    /* grid-template-columns: 1fr; */
    gap: 1rem 2rem;
    width: max-content;
  }
  .social-icon {
    justify-self: left;
    font-size: 1.75rem;
    display: flex;
    align-items: center;
    line-height: 1.5;
    text-align: center;
  }
  .social-icon:hover .fa {
    color: var(--clr-font);
  }
  .social-icons-container span {
    display: none;
    color: var(--clr-font);
    font-size: 1.25rem;
    margin-left: 1rem;
    text-transform: capitalize;
    transition: var(--transition);
  }
  .social-icon:hover {
    transform: scale(1.01);
  }
}

/* 
======================
FOOTER
======================
*/

.footer {
  background: var(--clr-black);
  text-align: center;
  padding: 0 1rem;
}
.footer-text {
  font-size: 1rem;
  margin: 0.75rem;
}
@media screen and (min-width: 792px) {
  .footer-text {
    padding: 0.5rem 1rem;
    font-size: 1.1rem;
  }
}

.footer p {
  color: var(--clr-grey-9);
  line-height: 1.25;
  margin: 0.5rem 0;
}

.footer a {
  transition: var(--transition);
  color: var(--clr-grey-9);
  text-decoration: underline;
}
.footer-img-block,
.footer-text-2 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer-img {
  width: 1.5rem;
  height: 1.5rem;
}
.footer a:hover {
  color: var(--clr-secondary);
}

/* 
#####################
PAGES
#####################
*/

main {
  min-height: 100vh;
  display: grid;
  grid-template-rows: 1fr auto;
}
.page {
  background: var(--clr-body-bcg-2);
}
.nav-2 {
  background: var(--clr-body-bcg);
}
.page-center {
  min-height: calc(100vh - 80px - 67px);
}
.page-title {
  margin: 0 auto;
  background: var(--clr-body-bcg-3);
  padding: 4rem 1rem;
}
.page-title p {
  text-transform: none;
  margin: 2rem auto 0;
}
.page-title p a {
  color: var(--clr-font-2);
  text-decoration: underline;
  transition: all 0.3;
}
.page-title p a:hover {
  color: #1b9ce5;
}

@media screen and (max-width: 792px) {
  .nav-2 .nav-center {
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
  }
  .page-center {
    min-height: calc(100vh - 80px - 49px);
  }
  .nav-2 .toggle-theme {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
}

/* 
======================
ERROR PAGE
======================
*/

.error-page {
  display: grid;
  place-items: center;
  text-align: center;
}
.error-page h2 {
  text-transform: none;
  margin-bottom: 3rem;
}

/* 
======================
CONTACT PAGE
======================
*/

.form-center {
  max-width: 768px;
  margin: 0 auto;
}
.form-center article {
  margin: 4rem auto;
  max-width: 600px;
}
label {
  text-transform: uppercase;
}
textarea {
  resize: none;
}
.form-control {
  margin-top: 1rem;
  padding: 1rem 0.75rem;
  outline: none;
  width: 100%;
  background: transparent;
  border: 3px solid transparent;
  border-bottom: 3px solid var(--clr-primary);
  color: var(--clr-font-3);
  font-size: 1.25rem;
  border-radius: 0.35rem;
}
.form-control:focus {
  background: var(--clr-body-bcg);
}
.form .btn-container {
  margin: 0 auto 3rem;
}
.submit-btn {
  border-style: solid;
  border-color: var(--clr-primary);
}

/* 
======================
PROJECTS PAGE
======================
*/

.category-btn-container {
  max-width: 900px;
  display: flex;
  flex-wrap: wrap;
  padding: 1rem 0;
  gap: 1rem 0.5rem;
  margin: 0 auto;
}
.category-btn:first-child {
  background: var(--clr-secondary);
  color: var(--clr-white);
}
.category-btn {
  margin: 0 auto;
  letter-spacing: 1px;
  transition: all 0.2;
  border-radius: var(--radius);
  outline: none;
  font-size: 0.75rem;
  border: 1px solid var(--clr-secondary);
  padding: 0.5rem 0.75rem;
  background: transparent;
  color: var(--clr-secondary);
}
.category-btn:hover {
  background: var(--clr-secondary);
  color: var(--clr-white);
}
.active {
  background: var(--clr-secondary);
  color: var(--clr-white);
}
.unactive {
  opacity: 0.9 !important;
  background: transparent !important;
  color: var(--clr-secondary) !important;
}
.projects-container {
  display: grid;
  gap: 3rem;
  padding: 3rem 0;
  place-items: center;
}
@media screen and (min-width: 692px) {
  .projects-container {
    grid-template-columns: 1fr 1fr;
    gap: 4rem;
  }
}
@media screen and (min-width: 792px) {
  .category-btn {
    font-size: 1rem;
  }
}

@media screen and (min-width: 1080px) {
  .projects-container {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media screen and (min-width: 1280px) {
  .projects-container {
    grid-template-columns: repeat(auto-fill, minmax(362px, 1fr));
  }
}

.single-project {
  border-radius: var(--radius);
  box-shadow: var(--light-shadow);
  transition: var(--transition);
  max-width: var(--fixed-width);
}
.single-project:hover {
  transform: scale(1.01);
  box-shadow: var(--dark-shadow);
}

.single-project-image {
  border-top-left-radius: var(--radius);
  border-top-right-radius: var(--radius);
  height: 15rem;
  object-fit: cover;
}

.single-project-info {
  background: var(--clr-body-bcg-3);
  padding: 1rem 1.2rem;
  border-bottom-left-radius: var(--radius);
  border-bottom-right-radius: var(--radius);
}
.single-project-title {
  text-align: center;
  text-transform: uppercase;
}
.single-project-footer {
  text-transform: capitalize;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 1rem;
}
.single-project-footer a {
  color: var(--clr-font-3);
  transition: var(--transition);
  align-items: center;
}
.single-project-footer a:first-child {
  text-decoration: underline;
}
.single-project-footer .fa {
  font-size: 1.25rem;
  margin-right: 0.25rem;
  vertical-align: text-top;
}
.single-project-footer a:hover {
  color: var(--clr-primary);
}

/* 
======================
LINKS PAGE
======================
*/

.links-page .section {
  padding: 3rem 0;
}

.links-page-img {
  width: 9rem;
}

.links-info {
  text-align: center;
}

.links-info p {
  margin-bottom: 0;
}

.links-info h4 {
  margin: 1rem 0 0.25rem;
  letter-spacing: 1px;
}

.links-page-center {
  margin: 0 auto;
  max-width: 481px;
  text-align: center;
}

.links-page-center h4 {
  text-align: center;
  margin: 0.5rem 0;
}

.links-page-btn {
  border-radius: 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  text-align: center;
  outline: 2px solid var(--clr-secondary);
  outline-offset: 2px;
  margin: 1.5rem 0;
  background: var(--clr-secondary);
}

.links-page-btn:last-child {
  margin-bottom: 0;
}

.links-page-btn.hide-link {
  display: none;
}

.links-page-btn.latest-link {
  animation: scale-link 1.5s infinite ease-in-out;
}
@keyframes scale-link {
  0% {
    transform: scale(1);
    outline-color: var(--clr-primary);
  }
  33% {
    outline-color: rgb(194, 14, 14);
  }
  50% {
    transform: scale(1.05);
  }
  67% {
    outline-color: rgba(137, 43, 226, 0.753);
  }
  100% {
    transform: scale(1);
    outline-color: var(--clr-primary);
  }
}
.links-page-btn:hover {
  background: var(--clr-primary);
}
.links-page-btn img {
  width: 1.75rem;
  height: 1.75rem;
  object-fit: contain;
}

.links-page-btn .fa {
  vertical-align: middle;
  color: white;
  margin-left: 0.25rem;
  font-size: 1.25rem;
}

.fa {
  vertical-align: middle;
}
